import React from "react";
import { Download, Features, SectionWrapper } from "./components";
import assets from "./assets";
import styles from "./styles/Global";
const App = () => {
  return (
    <React.Fragment>
      <SectionWrapper
        title="Find Your Dream Job with JobTrove"
        description="JobTrove makes it easy to find your dream job by providing access to thousands of job postings from top employers"
        showBtn
        mockupImg={assets.homeHero}
        banner="banner"
      />
      <SectionWrapper
        title="Apply for Jobs On-the-Go"
        description="Apply for jobs anytime, anywhere, directly within the JobTrove app. Never miss out on a job opportunity again!"
        mockupImg={assets.homeCards}
        reverse
      />
      <Features />
      <SectionWrapper
        title="Deployment"
        description="JobTrove is built using Expo which runs natively on all users' devices. You can easily get your app into people's hands"
        mockupImg={assets.feature}
        reverse
      />
      <SectionWrapper
        title="A User-Friendly App with Powerful Features"
        description="This JobTrove app comprises three screens:
        The Home screen: allows users to search for jobs, enable Dark Mode for easier viewing, and see Popular and Nearby Jobs.
        The Job Details screen: displays all pertinent information about a particular job, such as job title, company name, and a button for applying.
        The Search Result screen: presents a list of job openings that meet the user's search criteria."
        mockupImg={assets.mockup}
        banner="banner02"
      />
      <Download />

      <div className="px-4 py-2 justify-center items-center bg-primary flex-col text-center banner04">
        <p className={`${styles.footerText} ${styles.whiteText}`}>
          Developed by{" "}
          <span
            className="font-bold cursor-pointer"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/aadila-bendahou/",
                "_blank"
              )
            }
          >
            Aâdila Bendahou{" "}
          </span>
          &copy; 2023
        </p>
      </div>
    </React.Fragment>
  );
};

export default App;
